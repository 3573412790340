import React from 'react';
import { Link } from 'react-router-dom';
import './Work.css';

const Work = () => {
  const workItems = [
    {
      id: 1,
      title: 'Project A',
      description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean euismod bibendum laoreet.',
      imageUrl: 'https://cdn.pixabay.com/photo/2018/08/18/13/26/interface-3614766_960_720.png',
      path: '/ProjectA'
    },
    {
      id: 2,
      title: 'Project B',
      description: 'Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.',
      imageUrl: 'https://cdn.pixabay.com/photo/2018/05/04/20/01/website-3374825_960_720.jpg',
      path: '/ProjectB'
    },
    {
      id: 3,
      title: 'Project C',
      description: 'Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.',
      imageUrl: 'https://cdn.pixabay.com/photo/2018/05/04/00/09/design-3372889_960_720.jpg',
      path: '/ProjectC'
    },
  ];

  return (
    <section className="work-section" id='work'>
        <div className='headingS'>
                 <div style={{ display: 'flex', justifyContent: 'center' }}>
                 <h2>Our</h2>
                 <h2 style={{ color: '#265ec6', marginLeft: '10px' }}>Work</h2>
                 </div>
        </div>
      <div className="containerW">
        <h2>Quality is not an act, it is a habit.</h2>
        <div className="work-cards">
          {workItems.map((workItem) => (
            <Link to={workItem.path} key={workItem.id}>
              <div className="work-card">
                <img src={workItem.imageUrl} alt={workItem.title} />
                <div className="work-card-content">
                  <h3>{workItem.title}</h3>
                  <p>{workItem.description}</p>
                  <a href="/">Learn More</a>
                </div>
              </div>
            </Link>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Work;

