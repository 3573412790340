import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import './Stemplate.css';
import Navbar from '../Navbar/Navbar';
import SCRim from './SCRimg.PNG';
import S1 from './S1.png';
import S5 from './S5.png';
import S6 from './S6.png';

function SCR() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  
    return (
      <>
        <Navbar/>
        <section className='SCR'>
        <div className='container grid two-column'>
          <div className='left'>
            <h1>Supply Chain Retail</h1>
            <p className='overview'>Service Overview</p>
            <p>We Cognithrive Solutions a software consulting firm provide end-to-end supply chain management services for retail businesses, from sourcing and procurement to inventory management and distribution. Our team of experts will work with you to customize a solution that meets the unique needs of your business. We pride ourselves on our ability to provide high-quality products at competitive prices, ensuring that your customers are always satisfied with their purchases.</p>
          </div>
          <div className='right'>
            <img src={SCRim} width={500} height={500} alt="supply chain retail" />
          </div>
        </div>
        </section>
        <p style={{fontSize: '30px', textAlign: 'center', marginLeft: '180px',marginRight :'180px'}}>We have the ability to collaborate and devise a customized solution that is tailored to your specific needs. Here's how we can make it happen:</p>
        <div className="abtcontainer">
  <div className="image-container">
    <img src={S1} width={200} height={200} alt="img" />
    <h4>Modern Software Delivery</h4>
    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit Lorem ipsum dolor sit amet, consectetur adipiscingelit.Description - Lorem ipsum dolor sit amet, consectetur adipiscing elit Lorem ipsum dolor sit amet, consectetur.</p>
  </div>
  <div className="image-container">
    <img src={S5} width={200} height={200} alt="img" />
    <h4>Data & Analytics</h4>
    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit Lorem ipsum dolor sit amet, consectetur adipiscingelit.Description - Lorem ipsum dolor sit amet, consectetur adipiscing elit Lorem ipsum dolor sit amet, consectetur.</p>
  </div>
  <div className="image-container">
    <img src={S6} width={200} height={200} alt="img" />
    <h4>DevOps</h4>
    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit Lorem ipsum dolor sit amet, consectetur adipiscingelit.Description - Lorem ipsum dolor sit amet, consectetur adipiscing elit Lorem ipsum dolor sit amet, consectetur.</p>
  </div>
</div>
<div className='Scontact'>
  <p>If you're interested in learning more about our supply chain retail services, please contact us today to schedule a consultation. Looking forward to work with you!</p>
  <nav className="contact">
        <Link to="/contact">
        <button className="contactbutton">Contact</button>
      </Link>
  </nav>    
</div>
</>
    )}

export default SCR;
