import React, { useState, useEffect } from 'react';
import './Navbar.css';
import logo from './logo.png';
import { Link } from 'react-router-dom';
import { Link as ScrollLink } from 'react-scroll';

const scrollTargets = [
  { id: 'services', query: 'scrollToServices=true', offset: -60 },
  { id: 'Aboutus', query: 'scrollToAboutus=true', offset: 60 },
  { id: 'work', query: 'scrollTowork=true', offset: -40 },
  { id: 'contact', query: 'scrollToContact=true', offset: -40 }
];

export default function Navbar() {
  const [location, setLocation] = useState(window.location);

  useEffect(() => {
    setLocation(window.location);
  }, []);

  useEffect(() => {
    scrollTargets.forEach(target => {
      if (location.search === `?${target.query}`) {
        document.getElementById(target.id).scrollIntoView({ behavior: 'smooth', block: 'start', offset: target.offset  });
      }
    });
  }, [location]);

  return (
  <nav className="nav">
  <Link to="/">
    <img id="logo" src={logo} width={40} height={40} alt="My Logo" onClick={() => {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }} />
    <span className="nav-text" onClick={() => {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }}>Cognithrive Solutions</span>
  </Link>
      <ul>
        <li>
          {location.pathname === '/' ? (
            <ScrollLink to="services" spy={true} smooth={true} offset={-60} duration={600}>
              Services
            </ScrollLink>
          ) : (
            <Link to="/?scrollToServices=true">
              Services
            </Link>
          )}
        </li>
        <li>
          {location.pathname === '/' ? (
            <ScrollLink to="Aboutus" spy={true} smooth={true} offset={60} duration={600}>
              About Us
            </ScrollLink>
          ) : (
            <Link to="/?scrollToAboutus=true">
              About Us
            </Link>
          )}
        </li>
        <li>
          {location.pathname === '/' ? (
            <ScrollLink to="work" spy={true} smooth={true} offset={-40} duration={600}>
              Work
            </ScrollLink>
          ) : (
            <Link to="/?scrollTowork=true">
              Work
            </Link>
          )}
        </li>
      </ul>
      <nav className="contact">
        <ul className="margin">
          <li>
            {location.pathname === '/' ? (
              <ScrollLink to="contact" spy={true} smooth={true} offset={-40 }duration={600}>
                Contact
              </ScrollLink>
            ) : (
              <Link to="/?scrollToContact=true">
                Contact
              </Link>
            )}
          </li>
        </ul>
      </nav>
    </nav>
  );
}
