import React, { useEffect } from 'react';
import webdevimg from './webdevimg.PNG';
import './Stemplate.css';
import Navbar from '../Navbar/Navbar';
import S2 from './S2.png';
import S3 from './S3.png';
import S4 from './S4.png';
import { Link } from 'react-router-dom';

function Webdev() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
    return (
    <><Navbar />
    <section className='SCR'>
    <div className='container grid two-column'>
      <div className='left'>
        <h1>Web Development</h1>
        <p className='overview'>Service Overview</p>
        <p>We Cognithrive Solutions a software consulting firm that provides a range of web development services to help businesses establish a strong online presence and achieve their goals. With our expertise in web development technologies, we can help you build custom, responsive, and scalable websites that meet your unique business needs.</p>
      </div>
      <div className='right'>
        <img src={webdevimg} width={600} height={500} alt="img" />
      </div>
    </div>
    </section>
    <p style={{fontSize: '30px', textAlign: 'center', marginLeft: '180px',marginRight :'180px'}}>We have the ability to collaborate and devise a customized solution that is tailored to your specific needs. Here's how we can make it happen:</p>
    <div className="abtcontainer">
  <div className="image-container">
    <img src={S2} width={200} height={200} alt="img" />
    <h4>Modern Software Delivery</h4>
    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit Lorem ipsum dolor sit amet, consectetur adipiscingelit.Description - Lorem ipsum dolor sit amet, consectetur adipiscing elit Lorem ipsum dolor sit amet, consectetur.</p>
  </div>
  <div className="image-container">
    <img src={S3} width={200} height={200} alt="img" />
    <h4>Data & Analytics</h4>
    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit Lorem ipsum dolor sit amet, consectetur adipiscingelit.Description - Lorem ipsum dolor sit amet, consectetur adipiscing elit Lorem ipsum dolor sit amet, consectetur.</p>
  </div>
  <div className="image-container">
    <img src={S4} width={200} height={200} alt="img" />
    <h4>DevOps</h4>
    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit Lorem ipsum dolor sit amet, consectetur adipiscingelit.Description - Lorem ipsum dolor sit amet, consectetur adipiscing elit Lorem ipsum dolor sit amet, consectetur.</p>
  </div>
</div>
      <div className='Scontact'>
        <p>If you're interested in learning more about our web development services, please contact us today to schedule a consultation.Looking forward to work with you!</p>
        <Link to="/contact">
        <button className="contact-button">Contact</button>
      </Link>
</div></>  
    );
  }

export default Webdev