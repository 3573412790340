import React from 'react';
import './Trusted.css';
import T1 from './T1.jpg';
import T2 from './T2.jpg';
import T3 from './T3.jpg';
import T4 from './T4.jpg';

  const TrustedExperts = () => {
    return (
      <div>
        <div className='headingS'>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
         <h2>Trusted</h2>
         <h2 style={{ color: '#265ec6', marginLeft: '10px' }}>Experts</h2>
        </div>
        </div>
        <div className="Trusted-container">
          <img src={T1}alt="Expert 1" />
          <img src={T2}alt="Expert 2" />
          <img src={T3} alt="Expert 3" />
          <img src={T4} alt="Expert 4" />
        </div>
      </div>
    );
  };
  
  export default TrustedExperts;
  






    


