import React from 'react';
import './Hero.css';
import bgc from './bgc.png';
import { Link as ScrollLink } from 'react-scroll';


class Hero extends React.Component {
  render() {
    return (
      <div className='container grid two-column'>
        <div className='left'>
        <div className="animated-text-container">
        <h1 className="animated-text">Your Vision Our Solution</h1>
        </div>
          <div className='description'> 
           <p className="hero-tagline">Welcome to Cognithrive Solutions a software consulting firm, where we strive to help businesses of all sizes to leverage technology for their growth and success.</p>
            < ScrollLink to = "Aboutus" spy={true} smooth={true} offset={-60} duration={600}>
           <button className="hero-button">
            Learn More
            </button>
            </ScrollLink>
          </div> 
        </div>

        <div className='right'>
         <div className='ImageH'>
         <img  src={bgc} width={600} height = {550} alt="img" />
         </div>
        </div>
      </div>
    );
  }
}

export default Hero;
