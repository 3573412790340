import React from 'react';
import './Services.css'
import Card from '../Card/card';
import { Link } from 'react-router-dom';
import webdevimg from '../Services/webdevimg.png';
import SCR from '../Services/SCR.png';

class Services extends React.Component {
    render() {
        return(
            <div className='Services' id='services'>
                <div className='headingS'>
                 <div style={{ display: 'flex', justifyContent: 'center' }}>
                 <h2>Our</h2>
                 <h2 style={{ color: '#265ec6', marginLeft: '10px' }}>Solutions</h2>
                 </div>
                 </div>
                  <div className='Service_cards'> 
                  <Link to="/webdev" style={{ textDecoration: 'none' }}>
                    <Card title="Web Development" imageUrl= {webdevimg} description=" We Cognithrive Solutions a software consulting firm that provides a range of web development services to help businesses establish a strong online presence and achieve their goals." />
                  </Link>
                  <Link to="/supply_chain_retail" style={{ textDecoration: 'none' }}>
                    <Card title="Supply Chain Retail" imageUrl= {SCR} description="We Cognithrive Solutions a software consulting firm provide end-to-end supply chain management services for retail businesses, from sourcing and procurement to inventory management and distribution. " />
                  </Link>

                 </div>
             </div>    
        );
    }
} 
export default Services;

