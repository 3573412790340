import React, { useEffect } from 'react';
import Navbar from '../Navbar/Navbar';
import { Link } from 'react-router-dom';

function ProjectA() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
    return (
    <><Navbar />
    <section className='SCR'>
    <div className='container grid two-column'>
      <div className='left'>
        <h1>Project C</h1>
        <p className='overview'>About Project</p>
        <p>The website features a clean and modern design, with vibrant colors and bold typography that reflect the organization's mission and values. The homepage includes an overview of the organization's work, as well as links to various sections of the site, including news and events, resources, and a member directory.</p>
      </div>
      <div className='right'>
        <img src="https://cdn.pixabay.com/photo/2018/05/04/00/09/design-3372889_960_720.jpg" width={600} height={550} alt="img" />
      </div>
    </div>
    </section>
      <div className='Scontact'>
        <p>If you're interested in learning more about our web development services, please contact us today to schedule a consultation.Looking forward to work with you!</p>
        <Link to="/contact">
        <button className="contact-button">Contact</button>
      </Link>
</div></>  
    );
  }

export default ProjectA