import React, { useState } from 'react';
import './card.css';


const Card = ({ title, description,imageUrl }) => {
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  return (
    <div
      className="card"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <h2 style={{ textDecoration: 'none' }}>{title}</h2>
      <img src={imageUrl} width={100} height={100} alt={title} />
      <button className="explore-button">Explore</button>
      <div className={`card-content ${isHovered ? 'hovered' : ''}`}>
        <h2>{title}</h2>
        <p>{description}</p>
        <button className="explore-button" style={{ border: "1px solid #265ec6" }}>Explore</button>
      </div>
    </div>
  );
};

export default Card;

