import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Webdev from './components/Stemplate/Webdev';
import Home from './pages/Home';
import SCR from './components/Stemplate/supply_chain_retail';
import Contact from './components/Contact/Contact';
import ProjectA from './components/Projects/ProjectA';
import ProjectB from './components/Projects/ProjectB';
import ProjectC from './components/Projects/ProjectC';

function App() {
  return (
    <div>
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route exact path="/webdev" element={<Webdev />}/>
        <Route exact path="/supply_chain_retail" element={<SCR />}/>
        <Route exact path='/contact' element ={<Contact/>}/>
        <Route exact path='/ProjectA' element={<ProjectA/>}/>
        <Route exact path='/ProjectB' element={<ProjectB/>}/>
        <Route exact path='/ProjectC' element={<ProjectC/>}/>
      </Routes>
      </div>
  );
}
export default App
