import React from 'react';
import './About.css';
import bgimg from './bgimg.png';

function About() {
  return (
    <div className='Aboutus' id='Aboutus'>
      <div className='container grid two-column container-scroll'>
        <div className='left'>
          <h1>About Cognithrive Solutions</h1>

          <div className='description'> 
            <h2>About Us</h2>
            <p className="hero-tagline">Welcome to Cognithrive Solutions a software consulting firm, where we strive to help businesses of all sizes to leverage technology for their growth and success.</p>
          </div> 

          <div className='description'> 
            <h2>Our vision</h2>
            <p className="hero-tagline">Our vision is to be a trusted partner for businesses in their technology journey, by providing high-quality and innovative solutions that deliver measurable results.</p>
          </div> 

          <div className='description'> 
            <h2>Our mission</h2>
            <p className="hero-tagline">Our mission is to empower businesses to achieve their goals by providing technology solutions that are customized, scalable, and cost-effective.</p>
          </div> 

        </div>

        <div className='right'>
          <div className='ImageH'>
            <img src={bgimg} width={600} height={600} alt="img" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default About;








