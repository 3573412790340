import React from 'react';
import './Team.css'
import mayank from './mayank.jpg';
import anurag from './anurag.jpg';

const Team = () => {
  return (
    <div className="team-container">
      <div className='headingS'>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
         <h2>Our</h2>
         <h2 style={{ color: '#265ec6', marginLeft: '10px' }}>Team</h2>
        </div>
        </div>
      <div className="team-member">
        <img src= {mayank} alt="Person 1"  />
        <h3>Mayank Tripathi</h3>
      </div>
      <div className="team-member">
        <img src= {anurag} alt="Person 2" />
        <h3>Anurag Chakravarty</h3>
      </div>
    </div>
  );
}

export default Team;

