import React from 'react';
import Navbar from '../components/Navbar/Navbar';
import Hero from '../components/Hero/Hero';
import Trusted from '../components/trustedby/Trusted';
import Contact from '../components/Contact/Contact';
import Services from '../components/Services/Services';
import Team from '../components/Team/Team';
import Work from '../components/Work/Work';
import About from '../components/About/About';


class Home extends React.Component {
  render() {
    return (
        <div id="Home">
            <Navbar />
            <Hero />
            <Trusted />
            <Services />
            <Work/>
            <About/>
            <Team />
            <Contact />
        </div>
    );
  }
}

export default Home;
