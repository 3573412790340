import React, { useState, useRef } from 'react';
import './Contact.css';
import emailjs from 'emailjs-com';
import axios from 'axios';

function Contact() {
  const formRef = useRef();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  const sendEmail = (e) => {
    e.preventDefault();
    emailjs.init('qdJHyvbTdVJtmyN8l');
    emailjs.sendForm('service_uqnuty7', 'template_h9dj6vw', formRef.current, 'qdJHyvbTdVJtmyN8l')
      .then((result) => {
        console.log(result.text);
      }, (error) => {
        console.log(error.text);
      });

      setName("");
      setEmail("");
      setMessage("");

      formRef.current.reset();

    const formData = {
      name: name,
      email: email,
      message: message
    };

    axios.post('http://localhost:3001/api/Contact', formData)
      .then((response) => {
        console.log(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };


  return (
    <div className='contactus' id='contact'>
      <div className='headingS'>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <h2>Contact</h2>
          <h2 style={{ color: '#265ec6', marginLeft: '10px' }}>Us</h2>
        </div>
      </div>
      <div className='containers grid two-column'>
        <div className='leftcontact'>
          <h1>Get in Touch</h1>
          <div className="contact-form">
            <form ref={formRef} onSubmit={sendEmail}>
              <div className="form-group">
                <label htmlFor="name">Name</label>
                <input
                  type="text"
                  id="name"
                  name="name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  placeholder="Your name"
                />
              </div>
              <div className="form-group">
                <label htmlFor="email">Email</label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder="Your email"
                />
              </div>
              <div className="form-group">
                <label htmlFor="message">Message</label>
                <textarea
                  id="message"
                  name="message"
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                  placeholder="Enter your message here"
                />
              </div>
              <button type="submit">Submit</button>
            </form>
          </div>
        </div>
        <div className='rightcontact'>
          <h3>Email</h3>
          <p>info@cognithrivesolutions.com</p>
          <h3>Location</h3>
          <p>Emerald Heights</p>
          <div className='cmap'>
            <iframe title="Location Map" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3508.951632320566!2d77.3542093150315!3d28.42071628250182!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390cdd3d6e75a963%3A0x885a4a482da04a23!2sEmerald%20Heights!5e0!3m2!1sen!2sin!4v1679680097599!5m2!1sen!2sin" width="80%" height="100%" style={{border:0}}allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
          </div>
        </div>
        </div>
      </div>    
  );
}
export default Contact;

